import React from "react";
import { Logo } from "../Logo";
import { FaTwitter, FaDiscord } from "react-icons/fa/";
import * as globalStyles from "../../styles/index.module.css"
import  './index.css'

const Header = () => {
  return (
    <>
      <header className={`${globalStyles.container} header`}>
        <Logo />
        <div className="links-block">
          <div className="discord-block">
            <a
              className="discord-link"
              href="https://discord.gg/7j9z4W2Gna"
              target="_blank"
              rel="noreferrer"
              aria-label="discord-link"
            >
              <FaDiscord />
            </a>
            <div className="text">
              <p className="white">1,153</p>
              <p>Members</p>
            </div>
          </div>
          <div className="twitter-block">
            <a
              className="twitter-link"
              href="https://twitter.com/SecretClanNFT"
              target="_blank"
              rel="noreferrer"
              aria-label="twitter-link"
            >
              <FaTwitter />
            </a>
            <div className="text">
              <p className="white">1,674</p>
              <p>Followers</p>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export { Header };
