import { Link } from "gatsby";
import React from "react";
import './index.css'

const Logo = () => {
  return (
    <>
      <Link to="/">
        <div className="logo-container">
          <div className="logo"></div>
        </div>
      </Link>
    </>
  )
}

export { Logo }