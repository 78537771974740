import React from "react";
import { FaTwitter, FaDiscord} from "react-icons/fa/";
import { Logo } from "../Logo";
import * as styles from "../../styles/index.module.css"
import "./index.css"

const Footer = () => {
  return (
    <>
      <footer className={`${styles.container} footer`}>
        <div className="top-block">
          <Logo />
          <div className="social-links">
            <a
              className="footer-social-link"
              href="https://discord.gg/7j9z4W2Gna"
              target="_blank"
              rel="noreferrer"
              aria-label="discord-link"
            >
              <FaDiscord />
            </a>
            <a
              className="footer-social-link"
              href="https://twitter.com/SecretClanNFT"
              target="_blank"
              rel="noreferrer"
              aria-label="twitter-link"
            >
              <FaTwitter />
            </a>
          </div>
        </div>
        <div className="footer-text">
          <p>© 2021-2022. All rights reserved.</p>
          {/* <Link className="footer-link first" to="/terms">Terms of Use</Link>
          <Link className="footer-link" to="/privacy">|  Privacy Policy</Link> */}
        </div>
      </footer>
    </>
  );
};

export { Footer };
